
.avatar-placeholder {
  width: 100px;
  height: 100px;
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}
