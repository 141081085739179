
img {
  height: 40px;
}

.avatar-image {
  &:hover {
    transform: scale(1.1);
  }
}
